import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { NotificationResponse } from '../../models/Notification';
import { categoriseByTime, NotificationPeriod } from '../../utils/NotificationUtils';
import NotificationBellIcon from '../shared/icon/NotificationBellIcon';
import Loader from '../shared/Loader';
import NotificationDropdownItem from './NotificationDropdownItem';

type Props = {
  className?: string;
  data: NotificationResponse[];
  isLoading: boolean;
  markAsRead: (id: string) => void;
  onShowMore?: (() => void) | null;
  onItemClick: () => void;
};

const NotificationDropdownSection: FC<Props> = (props) => {
  const { className, data, isLoading, markAsRead, onShowMore, onItemClick } = props;
  const { t } = useTranslation('common');

  const noNotifications = data.length === 0 && !isLoading;

  const [lastElementRef] = useInfiniteScroll(onShowMore ? onShowMore : null, isLoading);

  const categorisedData = useMemo(() => categoriseByTime(data, t), [data, t]);

  return noNotifications ? (
    <div className={`flex h-full w-full flex-col items-center justify-center text-center ${className}`}>
      <NotificationBellIcon className="mb-2 h-10 w-10 bg-white text-black" />
      <div>{t('notifications.no-notifications', { type: t('notifications.categories.unread') })}</div>
      <div className="text-gray-2">{t('notifications.no-notifications-subtitle')}</div>
    </div>
  ) : (
    <div className={`flex flex-col ${className}`}>
      {isLoading && (
        <div className="relative py-20">
          <Loader size={14} />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col">
          {(categorisedData || []).map((category: NotificationPeriod, i) => (
            <div key={v4()} className="flex flex-col">
              <div className="bg-accent-light-5 my-2 px-2 py-2 font-normal">{category.period}</div>
              {category.notifications?.map((notification, j) => {
                const isLast = data.length === i + 1 && category.notifications?.length === j + 1;
                return (
                  <div key={notification.id} ref={isLast ? lastElementRef : undefined} className="border-gray-5 my-2 border-b-2 last:border-b-0">
                    <NotificationDropdownItem markAsRead={(id: string) => markAsRead(id)} notification={notification} onItemClick={onItemClick} />
                  </div>
                );
              })}
            </div>
          ))}

          {isLoading && (
            <div className="relative flex justify-center py-20">
              <Loader size={16} centered={false} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationDropdownSection;
