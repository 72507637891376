import { FC, useCallback, useMemo, useRef, useState } from 'react';
import DropDownIcon from '../../shared/icon/composite/DropDownIcon';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../../recoil/atoms/Auth';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from '../../../types/Languages';
import i18next from 'i18next';
import GlobeIcon from '../../shared/icon/GlobeIcon';
import { EventSystem } from '../../../events/EventSystem';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import Building2Icon from '../../shared/icon/Building2Icon';
import Tooltip from '../../shared/Tooltip';
import RadioButton from '../../shared/form-control/RadioButton';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

type LanguageSelectorProps = {
  onChange?: (language: string) => void;
};

const LanguageDropdown: FC<LanguageSelectorProps> = (props) => {
  const { onChange } = props;
  const [open, setOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const currentClient = useRecoilValue(currentClientAtom);

  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const currentUser = useRecoilValue(currentUserAtom);

  const languageOptions = useMemo(() => {
    // Create a new array from the readOnly supportedLanguages and sort it
    const sortedLanguages = [...supportedLanguages].sort((a, b) => {
      if (a.id === currentClient?.language) return -1;
      if (b.id === currentClient?.language) return 1;
      return 0;
    });

    return sortedLanguages.map((lang) => ({
      id: lang.id,
      value: lang.value,
      text: t(`languages-with-code.${lang.id}`),
    }));
  }, [currentClient?.language, t]);

  const selectedLanguageOption = useMemo(() => languageOptions.find((x) => x.id === currentUser?.language), [currentUser?.language, languageOptions]);

  const changeLanguage = useCallback(
    (code: (typeof languageOptions)[number]['id']) => {
      EventSystem.fireEvent('language-changed', code);
      i18next.changeLanguage(code);
      onChange && onChange(code);
    },
    [onChange],
  );

  return (
    <div ref={popupRef}>
      <div className="relative flex cursor-pointer select-none items-center" ref={refs.setReference} {...getReferenceProps()}>
        <GlobeIcon className="h-8 w-8 pr-2" />
        <div className="pr-2 font-medium uppercase">{selectedLanguageOption?.value}</div>
        <DropDownIcon className="h-4 w-4" open={open} />
      </div>
      {open && (
        <FloatingFocusManager context={context} modal={false}>
          <div className="px-2 py-1" ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 9000 }} {...getFloatingProps()}>
            <ul className="border-1 w-48 border-gray-200 bg-white shadow-md">
              {languageOptions.map((lang) => (
                <li
                  key={lang.value}
                  className="cursor-pointer select-none border-t bg-white px-3 hover:bg-gray-200"
                  {...mouseAndKeyboardCallbackProps(() => changeLanguage(lang.value))}
                >
                  <div className="flex items-center justify-between">
                    <RadioButton value={currentUser?.language === lang.value} label={lang.text} size="small" />
                    {currentClient?.language === lang.value && (
                      <Tooltip text={t('company-default-language')}>
                        {(tooltip) => (
                          <span {...tooltip}>
                            <Building2Icon className="text-gray-2 h-5 w-5" />
                          </span>
                        )}
                      </Tooltip>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

export default LanguageDropdown;
