import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ClientFormUserOnly } from '../../models/ClientFormUser';
import { NotificationResponse } from '../../models/Notification';
import { Placeholders, UserPlaceholder } from '../../models/Placeholders';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import DateUtils from '../../utils/DateUtils';
import NotificationUtils from '../../utils/NotificationUtils';
import { ImageSize, ProfileImageStack } from '../ownership/ProfileImageStack';
import Checkbox from '../shared/form-control/Checkbox';
import Tooltip from '../shared/Tooltip';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { interpolate } from '../../utils/interpolation/InterpolationUtils';

interface Props {
  notification: NotificationResponse;
  markAsRead: (id: string) => void;
  onItemClick: () => void;
}

const NotificationDropdownItem = ({ notification, markAsRead, onItemClick }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const currentUser = useRecoilValue(currentUserAtom);

  const user = useMemo(() => {
    const user = notification.placeholders[Placeholders.PRIMARY_USER] as UserPlaceholder;
    return user || null;
  }, [notification.placeholders]);

  const notifictionText = interpolate(notification.text, notification.placeholders);
  const linkTo = NotificationUtils.getNotificationUrl(notification.placeholders);

  return (
    <div className="text-color-3 text-dpm-14 flex flex-col items-start rounded-md bg-white px-2 pb-2">
      <Link
        to={linkTo}
        onClick={() => {
          markAsRead(notification.id);
          onItemClick();
        }}
        key={notification.id}
        className="flex min-w-full max-w-0 items-center gap-2"
      >
        <div className="flex items-center gap-2 self-start">
          <Tooltip text={t('notifications.mark-as-read')}>
            {(tooltip) => (
              <span
                {...tooltip}
                {...mouseAndKeyboardCallbackProps((e) => {
                  e.preventDefault();
                  e.stopPropagation();
                })}
              >
                {markAsRead && <Checkbox dot value={!notification.isRead} onChange={() => markAsRead(notification.id)} />}
              </span>
            )}
          </Tooltip>
          <ProfileImageStack
            size={ImageSize.S}
            users={[
              user
                ? ({
                    id: user.id,
                    firstName: user.userName.split(' ')[0],
                    lastName: user.userName.split(' ').slice(1).join(' '),
                  } as ClientFormUserOnly)
                : (currentUser as ClientFormUserOnly),
            ]}
          />
        </div>
        <div className="my-1 flex-grow">{notifictionText}</div>
        <div className="text-dpm-12 ml-2 self-end whitespace-nowrap">{DateUtils.formatDateOrTime(new Date(notification.createdUtc))}</div>
      </Link>
    </div>
  );
};

export default NotificationDropdownItem;
