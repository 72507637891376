import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../../recoil/atoms/Auth';

const LoggedInUser: FC = () => {
  const user = useRecoilValue(currentUserAtom);

  return (
    <span data-sensitive="true" className="mr-2 font-medium">
      {user?.firstName} {user?.lastName}
    </span>
  );
};

export default LoggedInUser;
